import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Про футбольні тренування Сучасна
			</title>
			<meta name={"description"} content={"Де рубці крепані - історія слави сітки"} />
			<meta property={"og:title"} content={"Про нас | Про футбольні тренування Сучасна"} />
			<meta property={"og:description"} content={"Де рубці крепані - історія слави сітки"} />
			<meta property={"og:image"} content={"https://ivanoilluminations.live/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ivanoilluminations.live/img/2817812.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ivanoilluminations.live/img/2817812.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ivanoilluminations.live/img/2817812.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ivanoilluminations.live/img/2817812.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ivanoilluminations.live/img/2817812.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ivanoilluminations.live/img/2817812.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="150px 0 150px 0" background="rgba(0, 0, 0, 0) url(https://ivanoilluminations.live/img/4.jpg) 0% 0% /cover repeat scroll padding-box" sm-padding="80px 0 80px 0" quarkly-title="Product-7">
			<Box min-width="100px" min-height="100px" display="flex" justify-content="flex-end">
				<Box
					min-width="100px"
					min-height="100px"
					width="400px"
					background="#ffffff"
					padding="50px 35px 50px 35px"
					display="flex"
					align-items="center"
					flex-direction="column"
					md-padding="35px 35px 35px 35px"
				>
					<Text margin="0px 0px 20px 0px" font="normal 600 32px/1.2 --fontFamily-sans" text-align="center">
						Про нас
					</Text>
					<Text margin="0px 0px 35px 0px" text-align="center" font="normal 400 18px/1.5 --fontFamily-sansHelvetica">
						Футбольна школа - Сучасна не просто заклад - це тигель, де футбольний талант вишуканий і відточений.Наш етос ґрунтується на переконанні, що кожен гравець має потенціал для величі.Заснована колективом футбольних любителів та колишніх гравців, слава Gridiron стала маяком для тих, хто прагне дістатися до нових висот у своїй футбольній кар'єрі.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-5">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
				Наша філософія
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#555a5f" sm-margin="0px 0px 50px 0px">
				Ми працюємо за принципом, що досконалість на футбольному полі досягається за допомогою дисципліни, наполегливості та глибокого розуміння гри.Наш підхід є всебічним, орієнтуючись на фізичну кондиціонування, розвиток навичок, тактичні знання та психічну міцність.
			</Text>
			<Box min-width="100px" min-height="100px" display="grid" grid-gap="50px 50px">
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Присвячений розвитку талантів
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Слава Gridiron - це більше, ніж просто навчальний майданчик - це спільнота, де гравці всіх рівнів можуть процвітати.Наші навчальні програми розроблені для того, щоб кинути виклик і надихнути, будь то молодий аспірант чи досвідчений гравець, який прагне вдосконалити свої навички.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Що нас відрізняє?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						• Інноваційні методи навчання: поєднання перевірених часом методів з інноваційними методами навчання, щоб підтримувати наші програми динамічними та ефективними.
<br />
<br />
• Комплексне побудова навичок: від фундаментальних тренувань до передової тактики, наша навчальна програма охоплює кожен аспект гри.
<br />
<br />
• Зосередьтеся на індивідуальному зростанні: персоналізований коучинг для вирішення конкретних потреб та цілей кожного гравця.
<br />
<br />
• Громадський та командний дух: сприяння почуттям товариства та командної роботи, що виходить за межі поля.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Наша прихильність до досконалості
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						У славі Gridiron ми прагнемо виховати наступне покоління футбольного таланту.Наша спадщина написана в досягненнях наших слухачів, як на полі, так і поза ним.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-3">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="65%"
				align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				justify-content="center"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					height="auto"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						min-height="100%"
						src="https://ivanoilluminations.live/img/5.jpg"
						object-fit="cover"
						left={0}
						top={0}
						right={0}
						position="absolute"
						display="block"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
			>
				<Box
					transform="translateY(0px)"
					width="100%"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
					padding="0px 0px 65% 0px"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						bottom={0}
						src="https://ivanoilluminations.live/img/6.jpg"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						top={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Приєднуйтесь до лав найсучаснішій футбольній школі - де подорож кожного гравця підтримується
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});